import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from '@fi/app/core/services/users.service';
import { UserForm } from '../interfaces/user-form.interface';

@Component({
  selector: 'user-upsert',
  template: `
    <div class="mt-10 mx-4">
      <user-form #elem [(ngModel)]="userForm"></user-form>

      <div class="flex flex-row-reverse justify-between mt-4">
        <button type="button" mat-raised-button color="primary" (click)="elem.form.valid && save()">Salva</button>
        <!-- <button *ngIf="id" type="button" mat-button color="primary">Torna alla lista</button> -->
      </div>
    </div>
  `,
  styles: [],
})
export class UtentiUpsertComponent implements OnInit {
  id: string;
  userForm: UserForm;

  constructor(
    private activatedRoute: ActivatedRoute,
    private usersService: UsersService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.id = params.id;
      if (this.id) {
        this.usersService.getUser(this.id).subscribe((user) => {
          this.userForm = user;
        });
      }
    });
  }

  save() {
    this.usersService.update(this.id, this.userForm).subscribe();
  }
}
