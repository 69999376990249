import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'utenti-upsert-component',
  template: `
    <fullstack-header></fullstack-header>
    <ng-template header-body>
      <h1 class="tx-title-1">Utente</h1>
      <h2 class="tx-subtitle mb-0">Creazione/Modifica</h2>
    </ng-template>
    <ng-template header-menu>
      <fullstack-tabs>
        <tab [label]="'Dettaglio'" [url]="'./main-info'"></tab>
        <ng-container *permissions="['user_manager', 'can_view_users_details']">
          <tab *ngIf="id" [label]="'Dati aggiuntivi'" [url]="'./additional-info'"></tab>
        </ng-container>
      </fullstack-tabs>
    </ng-template>

    <router-outlet></router-outlet>
  `,
})
export class UtentiUpsertTabsComponent implements OnInit {
  public id: string;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.id = params.id;
    });
  }
}
