import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppointmentLimitEntity, SitesService, UserMetaEntity, UserMetaService } from '@fi/app/client';
import { APPLICATION_ID, FiPermissions, FiRoles, ISite, getUpline } from '@fi/app/core';
import { AppConfigService } from '@fi/app/core/app-config/app-config.service';
import { AuthServices } from '@fi/app/core/services/auth.services';
import { UsersService } from '@fi/app/core/services/users.service';
import { setParentForm } from '@fi/app/core/utils';
import { IFormBuilder, IFormGroup } from '@rxweb/types';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { UTENTI_CONFIG_PROVIDER, UtentiModuleOptions } from '../../module.options';
@Component({
  selector: 'utenti-additional-data-form',
  templateUrl: 'additional-data.form.html',
})
export class UtentiAdditionalDataFrom implements OnInit {
  @Input() parentForm: UntypedFormGroup;
  form: IFormGroup<Partial<UserMetaEntity>>;
  fb: IFormBuilder = new UntypedFormBuilder();
  isUnAvaiableFormValue$;
  sites$: Observable<ISite[]>;
  parentsList$: Observable<UserMetaEntity[]>;
  APPLICATION_ID = APPLICATION_ID;
  currentAppId: string;
  userId: string;
  bcId: string;
  radius: number;
  roles: FiRoles[] = [];
  canEditRadius = false;
  userMeta: UserMetaEntity;
  canEditShowAgent;

  constructor(
    private usersService: UsersService,
    private userMetaService: UserMetaService,
    private authService: AuthServices,
    @Inject(UTENTI_CONFIG_PROVIDER)
    public utentiModuleOption: UtentiModuleOptions,
    private activatedRoute: ActivatedRoute,
    private appConfigService: AppConfigService,
    private sitesService: SitesService,
  ) {
    this.form = this.fb.group<Partial<UserMetaEntity>>({
      appointmentLimit: this.fb.group<AppointmentLimitEntity>({
        month: new UntypedFormControl(null, []),
        year: new UntypedFormControl(null, []),
        week: new UntypedFormControl(null, []),
      }),
      address: new UntypedFormControl(null, [Validators.required]),
      parent: new UntypedFormControl(null, []),
      candidateType: new UntypedFormControl([], []),
      availabilitySlots: new UntypedFormControl(null, []),
      isUnavailable: new UntypedFormControl(null, []),
      description: new UntypedFormControl(null, []),
      site: new UntypedFormControl(null, []),
      acceptStar: new UntypedFormControl(null, []),
    });
    this.isUnAvaiableFormValue$ = this.form.controls['isUnavailable'].valueChanges;
    this.sites$ = this.sitesService.findAll();
  }

  ngOnInit(): void {
    this.currentAppId = this.appConfigService.getApplicationId();

    if (this.currentAppId === APPLICATION_ID.FI) {
      this.form.get('appointmentLimit').patchValue({ month: 4, week: 1, year: 48 });
    }
    this.activatedRoute.queryParams
      .pipe(
        tap((params) => {
          this.userId = params.id;
        }),
        switchMap((_) => this.authService.getUserRoles()),
        tap((roles) => {
          this.roles = roles as FiRoles[];
          this.canEditRadius = this.roles.some(
            (r) =>
              [
                FiRoles.IS_REGIONAL_MANAGER,
                FiRoles.IS_AREA_MANAGER,
                FiRoles.IS_STORE_MANAGER,
                FiRoles.IS_ADMIN,
                FiRoles.IS_MAINTAINER,
                FiPermissions.CAN_VIEW_USERS_DETAILS,
              ].indexOf(r) >= 0,
          );
        }),
        switchMap((_) => this.userMetaService.findByFusionUserId(this.userId)),
        switchMap((user) => {
          const registration = user.registrations.find((r) => r.applicationId == APPLICATION_ID.FI);
          const roles = registration.roles;
          this.canEditShowAgent = roles.find((r) => getUpline(FiRoles.IS_AGENTE_SENIOR).includes(<FiRoles>r));

          /* const filterRole = this.usersService.calculateNetworkPriority(user, this.appConfigService.getApplicationId()); */
          this.parentsList$ = this.usersService.getUsersListDownline(['is_admin'] /* , filterRole */, null).pipe(
            map((parentList) => {
              return parentList.filter((l) => l._id != this.userId);
            }),
          );
          setParentForm(this.parentForm, this.form);

          return this.loadUserMeta();
        }),
      )
      .subscribe();
  }

  loadUserMeta() {
    return this.userMetaService.findByFusionUserId(this.userId).pipe(
      tap((val) => {
        this.bcId = val.bcId;
        this.userMeta = val;
        this.parentForm.patchValue(val);
        this.radius = val?.address?.radius;
      }),
    );
  }

  setRadius(radius: number) {
    this.form.get('address').value.radius = radius;
  }
}
