import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UserMetaEntity, UserMetaService, UserPreferencesService } from '@fi/app/client';
import { CsvUtilsService } from '@fi/app/core/services/csv-utils.service';
import { MenuItem } from 'primeng/api';
import { UserFormModalComponent } from './modals/upsert-modal/user-form-modal.component';
import { UTENTI_CONFIG_PROVIDER, UtentiModuleOptions } from './module.options';
import { LabelRoles } from '@fi/app/core';

@Component({
  selector: 'app-utenti-component',
  template: `
    <div class="h-full flex flex-col">
      <fullstack-header></fullstack-header>

      <ng-template header-body>
        <h1 class="tx-title-1">Utenti</h1>
        <h2 class="tx-subtitle mb-0">Lista</h2>
      </ng-template>

      <ng-template header-body>
        <div class="flex justify-end gap-2 mb-2">
          <button matAnchor mat-mini-fab (click)="add()" pTooltip="Crea utente" tooltipPosition="left" color="primary">
            <mat-icon>add</mat-icon>
          </button>
          <button
            matAnchor
            mat-mini-fab
            (click)="edit()"
            pTooltip="Modifica utente"
            tooltipPosition="left"
            color="primary"
            [disabled]="!selected"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            matAnchor
            mat-mini-fab
            (click)="download()"
            pTooltip="Esporta CSV"
            tooltipPosition="left"
            color="secondary"
          >
            <mat-icon>file_download</mat-icon>
          </button>
        </div>
        <div class="text-end align-items-center mt-2"></div>
      </ng-template>

      <fullstack-datatable
        class="flex-1"
        [list]="usersList"
        [cmItems]="cmItems"
        (selected)="selected = $event"
        [clientExport]="false"
        [showColumnSelector]="true"
        [visibleColumns]="visibleColumns"
        (selectedColumnsChoosed)="saveSelectedColumn($event)"
        (rowsChange)="saveRowCount($event)"
        [maxRows]="maxRows"
      >
        <div t-header>
          <div t-column [name]="'Nome'" field="firstName" clientFilter="firstName">
            <td *t-cell="let row">{{ row?.userObj.firstName }}</td>
          </div>
          <div t-column [name]="'Cognome'" field="lastName" clientFilter="lastName">
            <td *t-cell="let row">{{ row?.userObj.lastName }}</td>
          </div>
          <div t-column [name]="'Email'" field="email" clientFilter="email">
            <td *t-cell="let row">{{ row?.userObj.email }}</td>
          </div>
          <div t-column [name]="'Telefono'" field="mobilePhone" clientFilter="mobilePhone">
            <td *t-cell="let row">{{ row?.userObj.mobilePhone }}</td>
          </div>
          <div t-column [name]="'Ruolo'" field="roles" clientFilter="roles">
            <td *t-cell="let row">{{ row?.registrations[0]?.roles }}</td>
          </div>
          <div t-column [name]="'Attivo'" field="active">
            <td *t-cell="let row">{{ row?.userObj.active ? 'Attivo' : 'Non attivo' }}</td>
          </div>
          <div t-column [name]="'Ultimo Login'" field="lastLoginInstant">
            <td *t-cell="let row">{{ row?.userObj.lastLoginInstant | date: 'short' }}</td>
          </div>
          <div t-column [name]="'Nome Sede'" field="site.name" clientFilter="site.name">
            <td *t-cell="let row">{{ row?.site?.name }}</td>
          </div>
          <div t-column [name]="'Provincia Sede'" field="site.address.province" clientFilter="site.address.province">
            <td *t-cell="let row">{{ row?.site?.address?.province }}</td>
          </div>

          <div t-column [name]="'Codice fiscale'" field="fiscalCode" clientFilter="fiscalCode">
            <td *t-cell="let row">{{ row?.fiscalCode }}</td>
          </div>
          <div t-column [name]="'Abilitato'" field="isQualified" clientFilter="isQualified">
            <td *t-cell="let row">{{ row?.isQualified ? 'Si' : 'No' }}</td>
          </div>

          <div [name]="'Limite mensile'" t-column field="monthLimit" clientFilter="monthLimit">
            <td *t-cell="let row">{{ row?.monthLimit || '-' }}</td>
          </div>
        </div>
      </fullstack-datatable>
    </div>
  `,
})
export class UtentiComponent implements OnInit {
  cmItems: MenuItem[] = [
    {
      label: 'Dettaglio',
      icon: 'pi pi-fw pi-pencil',
      command: () => {
        this.gotoDetail();
      },
    },
    {
      label: 'Elimina',
      icon: 'pi pi-fw pi-trash',
      command: () => {
        this.delete();
      },
    },
  ];

  usersList: UserMetaEntity[];
  selected: UserMetaEntity = null;
  visibleColumns: Array<string> = [];
  tableRef = 'UtentiComponent';
  maxRows: number = 25;
  loading: boolean = false;

  upsertDialogOptions = {
    header: 'Crea utente',
    width: '600px',
    contentStyle: { overflow: 'hidden' },
    baseZIndex: 10,
    maximizable: true,
  };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(UTENTI_CONFIG_PROVIDER)
    public utentiModuleOption: UtentiModuleOptions,
    private userPreferencesService: UserPreferencesService,
    private csvUtils: CsvUtilsService,
    private dialog: MatDialog,
    private usersMetaService: UserMetaService,
  ) {}

  ngOnInit(): void {
    this.loadData();
    this.loading = true;
    this.userPreferencesService.findByTableRef(this.tableRef).subscribe((preferences) => {
      if (preferences?.fields?.length > 0) {
        this.visibleColumns = preferences.fields;
      }
      this.maxRows = preferences?.rowCount ?? this.maxRows;
      this.loading = false;
    });
  }

  private buildRoles(roles: string[]) {
    return roles
      ? roles
          .map((r) => (LabelRoles[r] ? LabelRoles[r] : null))
          .filter((role) => !!role)
          .join(', ')
      : '';
  }

  gotoDetail() {
    this.router.navigate([`../upsert/main-info`], {
      queryParams: { id: this.selected._id },
      relativeTo: this.activatedRoute,
    });
  }

  saveSelectedColumn(selectedColumns: Array<string>) {
    this.userPreferencesService
      .create({
        tableRef: this.tableRef,
        fields: selectedColumns,
      })
      .subscribe(() => {});
  }

  saveRowCount(rows: number) {
    this.userPreferencesService
      .create({
        tableRef: this.tableRef,
        rowCount: rows,
      })
      .subscribe(() => {});
  }

  download() {
    /* this.csvUtils.arrayToCsv(this.users$, 'users_list').subscribe(); */
  }

  add() {
    this.dialog
      .open(UserFormModalComponent, {
        ...this.upsertDialogOptions,
        data: {
          userId: this.selected?._id,
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.loadData();
      });
  }

  edit() {
    this.router.navigate([`../upsert/main-info`], {
      queryParams: { id: this.selected._id },
      relativeTo: this.activatedRoute,
    });
  }

  loadData() {
    this.usersMetaService.findAll().subscribe((res) => (this.usersList = res.map(u => { return {
      ...u, registrations: [
        {
          ...u.registrations[0],
          roles: this.buildRoles(u.registrations[0].roles) as any
        }
      ]
      }}
    )));
  }

  delete() {
    this.usersMetaService.deleteById(this.selected._id).subscribe(() => this.loadData());
  }
}
