import { Component, forwardRef } from '@angular/core';
import { FormBuilder, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { RoleEntity, RolesService } from '@fi/app/client';
import { TypedFormGroup } from '@fi/app/core/utils/typed-form-group';
import { FormElement } from '../../../../ui/core';
import { UserForm } from '../../interfaces/user-form.interface';
@Component({
  selector: 'user-form',
  templateUrl: './user-form.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => UserFormComponent),
      multi: true,
    },
  ],
})
export class UserFormComponent extends FormElement {
  rolesList: RoleEntity[] = [];
  form = this.fb.group({
    id: new FormControl<string | null>(null),
    firstname: new FormControl<string | null>(null, [Validators.required]),
    lastname: new FormControl<string | null>(null, [Validators.required]),
    username: new FormControl<string | null>(null, []),
    email: new FormControl<string | null>(null, [Validators.required]),
    phone: new FormControl<string | null>(null, []),
    password: new FormControl<string | null>(null, [Validators.required]),
    confirmPassword: new FormControl<string | null>(null, [Validators.required]),
    roles: new FormControl<string[] | null>(null, [Validators.required]),
  }) as TypedFormGroup<UserForm>;

  constructor(
    private fb: FormBuilder,
    private rolesService: RolesService,
  ) {
    super();
    this.loadData();

    this.form.controls.id.valueChanges.subscribe((v) => {
      if (v != null) {
        this.form.removeControl('password');
        this.form.removeControl('confirmPassword');
      }
    });
  }

  loadData() {
    this.rolesService.findAll().subscribe((r) => {
      this.rolesList = r;
    });
  }
}
