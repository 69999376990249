<form [formGroup]="form">
  <div class="grid grid-cols-2 gap-y-6 gap-x-4">
    <p-floatLabel>
      <input id="firstname" type="text" pInputText formControlName="firstname" class="w-full" />
      <label for="firstname">Nome</label>
    </p-floatLabel>

    <p-floatLabel>
      <input id="lastname" type="text" pInputText formControlName="lastname" class="w-full" />
      <label for="lastname">Cognome</label>
    </p-floatLabel>

    <p-floatLabel>
      <input id="email" type="text" pInputText formControlName="email" class="w-full" [disabled]="true" />
      <label for="email">Email</label>
    </p-floatLabel>

    <div *ngIf="form.contains('password')" class="flex gap-4 w-full">
      <p-floatLabel>
        <p-password
          id="password"
          placeholder="Password"
          [feedback]="false"
          [toggleMask]="true"
          styleClass="w-full"
          formControlName="password"
          inputStyleClass="w-full"
        ></p-password>
        <label for="password">Password</label>
      </p-floatLabel>
      <p-floatLabel>
        <p-password
          id="password_confirm"
          placeholder="Conferma password"
          [feedback]="false"
          formControlName="confirmPassword"
          [toggleMask]="true"
          styleClass="w-full"
          inputStyleClass="w-full"
        ></p-password>
        <label for="password_confirm">Conferma password</label>
      </p-floatLabel>
    </div>

    <p-floatLabel>
      <input id="mobilePhone" type="text" pInputText formControlName="phone" class="w-full" [disabled]="true" />
      <label for="mobilePhone">Cellulare</label>
    </p-floatLabel>

    <p-floatLabel>
      <p-multiSelect
        appendTo="body"
        inputId="roles"
        [options]="rolesList"
        formControlName="roles"
        optionLabel="label"
        optionValue="code"
        styleClass="w-full"
      />
      <label for="roles">Ruoli</label>
    </p-floatLabel>
  </div>
</form>
