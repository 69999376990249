<form [formGroup]="form" novalidate>
  <div>


    <div class="flex gap-2 mt-6">
      <app-input-address [options]="{ label: 'Indirizzo sede *' }" formControlName="address"> </app-input-address>

      <fullstack-input-box
        [ngModelOptions]="{ standalone: true }"
        [options]="{ label: 'Raggio *' }"
        [(ngModel)]="radius"
        [disabled]="!canEditRadius"
        (ngModelChange)="setRadius($event)"
      ></fullstack-input-box>

      <div *ngIf="!utentiModuleOption?.excludeField?.site">
        <p-floatLabel>
          <p-dropdown
            inputId="site"
            formControlName="site"
            [options]="sites$ | async"
            styleClass="w-full"
            [placeholder]="'Sede'"
            optionLabel="name"
            optionValue="_id"
          >
          </p-dropdown>
          <label for="site"> Sede di riferimento</label>
        </p-floatLabel>
      </div>
    </div>

    <div class="mt-6 flex ">

        <p-floatLabel class="w-full">
          <textarea id="description" class="w-full" rows="5" cols="30" pInputTextarea formControlName="description"> </textarea>
          <label for="description">Descrizione utente</label>
        </p-floatLabel>


    </div>
    <div *permissions="['appointment_config_manager']">
      <div class="row mb-4">
        <div class="col-sm-12">
          <header class="page-header">
            <h2 class="tx-title-3">Limiti opportunità</h2>
          </header>
        </div>
      </div>
      <div class="grid grid-cols-3 gap-x-4 mt-6" formGroupName="appointmentLimit">
        <fullstack-input-box [options]="{ label: 'Settimanali' }" formControlName="week"></fullstack-input-box>

        <fullstack-input-box [options]="{ label: 'Mensili' }" formControlName="month"></fullstack-input-box>

        <fullstack-input-box [options]="{ label: 'Annuali' }" formControlName="year"></fullstack-input-box>
      </div>
    </div>

    <!-- Network -->
    <div class="row my-4">
      <div class="col-6">
        <header class="page-header mb-2">
          <h2 class="tx-title-3">Network</h2>
        </header>
        <p-dropdown
          [options]="parentsList$ | async"
          [filter]="true"
          formControlName="parent"
          [filterFields]="['firstName', 'lastName']"
          optionValue="_id"
          [showClear]="true"
          styleClass="w-full"
          placeholder="Seleziona supervisore"
          filterPlaceholder="Nome o cognome utente"
        >
          <ng-template let-user pTemplate="selectedItem">
            <div class="flex align-items-center gap-2">
              <div *ngIf="user">{{ user?.userObj?.firstName }} {{ user?.userObj?.lastName }}</div>
              <div *ngIf="!user">Filtro Network</div>
            </div>
          </ng-template>

          <ng-template let-user pTemplate="item">
            <div class="flex align-items-center gap-2 succ">
              <div>{{ user?.userObj?.firstName }} {{ user?.userObj?.lastName }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </div>

  <div class="row my-4">
    <div class="col-sm-12">
      <header class="page-header">
        <h2 class="tx-title-3">Disponibilità</h2>
      </header>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <fullstack-input-check-box
        formControlName="isUnavailable"
        [options]="{ label: 'Non disponibile' }"
      ></fullstack-input-check-box>
      <small style="color: #f44336" *ngIf="(isUnAvaiableFormValue$ | async) === true"
        >L'utente non è disponibile, gli slot orari verrano ignorati.</small
      >
      <fullstack-day-time-slot formControlName="availabilitySlots"></fullstack-day-time-slot>
    </div>
  </div>
</form>
