import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UsersService } from '@fi/app/core/services/users.service';
import { UserForm } from '../../interfaces/user-form.interface';

@Component({
  selector: 'user-form-modal',
  templateUrl: './user-form.modal.html',
})
export class UserFormModalComponent implements OnInit {
  form = new FormGroup({
    user: new FormControl<UserForm>(null),
  });

  constructor(
    private usersService: UsersService,
    public dialogRef: MatDialogRef<UserFormModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  ngOnInit(): void {}

  submit() {}

  update(id: number) {}

  save() {
    if (this.form.valid) {
      this.usersService.create(this.form.value.user).subscribe(() => {
        this.dialogRef.close();
      });
    }
  }
}
