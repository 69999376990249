import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QrShowComponent } from '@fi/app/components/qr-show/qr-show.component';
import { DirectiveModule } from '@fi/app/core/directives/directive.module';
import { CoreModule, FormDirective, InputBoxModule, UiModule } from '@fi/app/ui';
import { ButtonModule } from 'primeng/button';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { PasswordModule } from 'primeng/password';
import { SiteModule } from '../site';
import { UtentiAdditionalDataFrom } from './forms/additional-data-form/additional-data.form';
import { UserFormComponent } from './forms/user-form/user-form.component';
import { UTENTI_CONFIG_PROVIDER, UtentiModuleOptions } from './module.options';
import { UtentiAdditionalInfo } from './pages/additional-info.component';
import { UtentiUpsertComponent } from './pages/utenti-upsert.component';
import { UtentiUpsertTabsComponent } from './pages/utenti-upsert-tabs.component';
import { UtentiRoutingModule } from './utenti-routing.module';
import { UtentiComponent } from './utenti.component';
import { UserFormModalComponent } from './modals/upsert-modal/user-form-modal.component';

@NgModule({
  declarations: [
    UtentiComponent,
    UtentiUpsertComponent,
    UtentiUpsertTabsComponent,
    UtentiAdditionalInfo,
    UtentiAdditionalDataFrom,
    UserFormModalComponent,
    UserFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    DirectiveModule,
    UiModule,
    ReactiveFormsModule,
    UtentiRoutingModule,
    SiteModule,
    InputBoxModule,
    QrShowComponent,
    DirectiveModule,
    PasswordModule,
    MessagesModule,
    ButtonModule,
    MultiSelectModule,
    DynamicDialogModule,
  ],
  providers: [DialogService],
  exports: [UtentiAdditionalDataFrom, DirectiveModule],
})
export class UtentiModule {
  static forRoot(config?: UtentiModuleOptions): ModuleWithProviders<UtentiModule> {
    return {
      ngModule: UtentiModule,
      providers: [
        {
          provide: UTENTI_CONFIG_PROVIDER,
          useValue: config,
        },
      ],
    };
  }
}
